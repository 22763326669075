import { React, useState, useEffect } from 'react'
import { fetchData } from '../services/fetch.js';

function Movies() {
  const [movies, setMovies] = useState([]); 
  const [active, setActive] = useState(false);

  useEffect(() => {
    console.log('updating');
    fetchData('movies').then( 
      data => setMovies(
        data.map( movie => ({
          movie : movie,
          src   : movie.replace('watch?v=', 'embed/') + '?autoplay=1',
          id    : movie.replace("https://www.youtube.com/watch?v=", ""),
          thumb : `//i.ytimg.com/vi/${movie.replace("https://www.youtube.com/watch?v=", "")}/hqdefault.jpg`,
        }))
      )
    )
  }, []);

  return (
    <div>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
        <h1 className="text-2xl font-semibold text-gray-900">Movies</h1>
      </div>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
        <div className="py-4 grid lg:grid-cols-2 gap-6">
          {movies.map((movie, index) => (
            <div className="rounded-lg overflow-hidden relative w-full h-64 lg:h-60 xl:h-80" key={index}>
              <button
                onClick={ () => setActive(movie) }
                style={{backgroundImage: `url(${movie.thumb})`}}
                className={active === movie ? 'hidden' : 'w-full h-full block bg-cover bg-center absolute left-0 right-0 top-0 bottom-0 z-50'}
              >
              </button>
              <iframe
                className="w-full h-full block bg-cover bg-center absolute left-0 right-0 top-0 bottom-0"
                src={active === movie ? movie.src : ''} 
                title="YouTube video player" 
                frameBorder="0" 
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
              ></iframe>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Movies