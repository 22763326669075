import React from "react";
import {
  ChartBarIcon,
  VolumeUpIcon,
  FingerPrintIcon,
  UserGroupIcon,
  FilmIcon,
  ExclamationIcon,
} from '@heroicons/react/outline'

import ActionIcons from '../components/ActionIcons';

const pages = [
  { name: 'Commands', href: '/commands', icon: ExclamationIcon, iconForeground: 'text-teal-700', iconBackground: 'bg-teal-50', excerpt: "See a list of bot commands." },
  { name: 'Audio Files', href: '/audio-files', icon: VolumeUpIcon, iconForeground: 'text-purple-700', iconBackground: 'bg-purple-50', excerpt: "Take a look at all the audio files the bot can play." },
  { name: 'Audio Stats', href: '/audio-stats', icon:  ChartBarIcon, iconForeground: 'text-sky-700', iconBackground: 'bg-sky-50', excerpt: "Find out which audio file has been played the most." },
  { name: 'Movies', href: '/movies', icon: FilmIcon, iconForeground: 'text-yellow-700', iconBackground: 'bg-yellow-50', excerpt: "Watch one of the many great tgk movies." },
  { name: 'Boomers', href: '/boomers', icon: FingerPrintIcon,iconForeground: 'text-rose-700', iconBackground: 'bg-rose-50',  excerpt: "See who is the biggest boomer in the clan."},
  { name: 'Active Users',  href: '/active-users', icon: UserGroupIcon, iconForeground: 'text-indigo-700', iconBackground: 'bg-indigo-50', excerpt: "See who is ready to frag right now." },
]

function Dashboard(props) {
  return (
    <div>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
        <h1 className="text-2xl font-semibold text-gray-900">Dashboard</h1>
      </div>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8 mt-4">
        <ActionIcons list={pages} setActivePage={props.setActivePage} />
      </div>
    </div>
  );
}

export default Dashboard