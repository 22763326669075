import { React, useState, useEffect } from 'react'

function AudioStats(props) {
  const [audioStats, setAudioStats] = useState([]);

  const fetchData = () => {
    fetch('https://api.tgk.family:8686/audiostats')
        .then((response) => response.json())
        .then((data) => {
          setAudioStats(data);
        })
  };

  const data = audioStats.filter((file) =>
    file.name.toLowerCase().includes(props.search.toLowerCase())
  );

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
        <h1 className="text-2xl font-semibold text-gray-900">Audio Stats</h1>
      </div>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
        <div className="my-4">
          <div className="full-table-height overflow-hidden overscroll-contain overflow-y-auto scroll-custom rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50 sticky top-0 shadow-sm">
                <tr>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase"
                  >
                    #
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    File Name
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Plays
                  </th>
                </tr>
              </thead>
              <tbody>
                {data.sort((a, b) => (b.points > a.points) ? 1 : -1).slice(0, 25).map((person, index) => (
                  <tr key={index} className={index % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{index + 1}</td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{person.name}</td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{person.points}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AudioStats