import { React, useState, useEffect } from 'react'
import { fetchData } from '../services/fetch.js';

function ActiveUsers(props) {
  const [activeUsers, setActiveUsers] = useState([]);
  
  const data = activeUsers.filter((user) =>
    user.user.toLowerCase().includes(props.search.toLowerCase())
  );

  useEffect(() => {
    fetchData('activeusers').then( data => setActiveUsers(data) );
  }, []);

  return (
    <div>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
        <h1 className="text-2xl font-semibold text-gray-900">Active Users - Work In Progress</h1>
      </div>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
        <div className="py-4 flex items-end w-full mx-auto justify-between md:justify-start flex-wrap">
          {data.map((user, index) => (
            <div key={index} className="inline-block relative mr-8 mb-8">
              <span className="relative inline-block">
                <img className="md:w-24 md:h-24 h-16 w-16 rounded-md" src={`/avatars/avatar-${index + 1}.png`} alt={user.user} />
                <span className="absolute bottom-0 right-0 transform translate-y-1/2 translate-x-1/2 block border-2 border-white rounded-full">
                  <span className="block h-4 w-4 rounded-full bg-green-400"></span>
                </span>
              </span>
              <h2 className="text-xs text-center font-medium text-gray-500 group-hover:text-gray-700">
                {user.user}
              </h2>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default ActiveUsers