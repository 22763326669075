
import { React, useState, useEffect } from 'react'
import {
  ClipboardCopyIcon,
} from '@heroicons/react/outline'
import { fetchData } from '../services/fetch.js';

function AudioFiles(props) {
  const [clicked, setClicked] = useState(false);
  const [audioFiles, setAudioFiles] = useState([]);

  function changeIconClasses(file) {
    setClicked(file)
    setTimeout( () => { setClicked('false') }, 250)
  }

  const data = audioFiles.filter((file) =>
    file.toLowerCase().includes(props.search.toLowerCase())
  );

  useEffect(() => {
    fetchData('audiofiles').then( data => setAudioFiles(data) );
  }, []);

  return (
    
    <div>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
        <h1 className="text-2xl font-semibold text-gray-900">Audio Files</h1>
      </div>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
        <div className="my-4">
          <div className="full-table-height overflow-hidden overscroll-contain overflow-y-auto scroll-custom rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50 sticky top-0 shadow-sm z-50">
                <tr>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Name
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Command
                  </th>
                </tr>
              </thead>
              <tbody>
                {data.map( ( file, index ) => (
                  <tr key={index} className={index % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{file.replace('.mp3', '')}</td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      <div className="relative">
                        <ClipboardCopyIcon 
                          onClick={
                            () => {
                              navigator.clipboard.writeText(`!play ${file.replace('.mp3', '')}`)
                              changeIconClasses(file)
                            }
                          }
                          className={clicked === file ? 'h-5 w-5 ml-6 text-green-500 cursor-pointer' : 'h-5 w-5 ml-6 text-blue-500 cursor-pointer'}
                        />
                        <span 
                          onClick={() => {changeIconClasses(file)}}
                          className={clicked === file ? 'absolute p-2 bg-white rounded-lg shadow-md text-xs left-14 bottom-0 opacity-1' : 'absolute p-2 bg-white rounded-lg shadow-md text-xs left-14 bottom-0 opacity-0 pointer-events-none transition'}
                        >
                          Copied to clipboard
                        </span>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AudioFiles