import { React, useState, useEffect } from 'react'
import { fetchData } from '../services/fetch.js';

function Boomers(props) {
  const [boomers, setBoomers] = useState([]);

  const data = boomers.filter((file) =>
    file.name.toLowerCase().includes(props.search.toLowerCase())
  );

  useEffect(() => {
    fetchData('boomers').then( data => setBoomers(data) );
  }, []);

  return (
    <div>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
        <h1 className="text-2xl font-semibold text-gray-900">Boomers</h1>
      </div>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
        <div className="my-4">
          <div className="full-table-height overflow-hidden overscroll-contain overflow-y-auto scroll-custom rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50 sticky top-0 shadow-sm">
                <tr>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Name
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Points
                  </th>
                </tr>
              </thead>
              <tbody>
                {data.sort((a, b) => (b.points > a.points) ? 1 : -1).map((person, index) => (
                  <tr key={index} className={index % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{person.name}</td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{person.points}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Boomers